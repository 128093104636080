import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Address } from '../models/address.model';
import { User } from '../models/user.model';
import { StaffMember } from '../models/staffMember.model';
import { Requirement } from '../models/requirement.model';
import { IServiceConfig } from '../models/serviceConfig.mode';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private showOtpLoaderSource = new BehaviorSubject(false);
  private isOtpSentSource = new BehaviorSubject(false);
  private isOtpVerifiedSource = new BehaviorSubject(false);
  private isLoggedInSource = new BehaviorSubject(false);
  // private maidServiceInfoSource = new BehaviorSubject(false);
  private profileNameSource = new BehaviorSubject('My Profile');
  private profileImageSource = new BehaviorSubject('/assets/images/user.svg');
  private tempAddressSource = new BehaviorSubject({} as Address);
  private resetTimeSlotsSource = new BehaviorSubject(false);
  private disableSpinnerSource = new BehaviorSubject(false);
  private voucherConfigSource = new BehaviorSubject(false);
  private crntUserSource = new BehaviorSubject({} as User);
  private crntJobDetailSource = new BehaviorSubject(false);
  private crntSnackbarInfoSource = new BehaviorSubject(false);
  private crntOpenPromoSource = new BehaviorSubject(false);
  private crntPageReloadSource = new BehaviorSubject(false);
  private crntStaffRatingSource = new BehaviorSubject("");
  private isMonthlyPackage = new BehaviorSubject(false);

  private maidServiceInfoKey = 'maidServiceInfoStorage';
  private customJobInfoKey = 'customJobInfoStorage';
  private defaultAddressIdKey = 'defaultAddressId';
  private customerInfoKey = 'customerInfoStorage';
  private urlParamsKey = 'urlParamStorage';
  private servicePolygonsKey = 'servicePolygons';
  private disabledPolygonsKey = 'disabledPolygons';
  private availableDiscountsKey = 'availableDiscounts';
  private superVoucherKey = 'superVoucher';
  private tempGeoKey = 'tempGeo';
  private guestAddressKey = 'guestAddress';
  private crntAddressKey = 'crntAddress';
  private zapFlowInfoKey = 'zapFlowInfo';
  private pageScrollInfoKey = 'pageScrollInfo';
  private ourServicesDataKey = 'ourServiceData';
  private selectedStaffKey = 'selectedStaff';
  private categoryBookingsKey = 'categoryBookings';
  private copiedPromoKey = 'copiedPromo';
  private parentPageKey = 'parentPage';
  private serviceConfigKey = 'serviceConfigKey';

  private maidServiceInfoSource = new BehaviorSubject(JSON.parse(sessionStorage
    .getItem(this.maidServiceInfoKey) as string) ?? false);
  private customJobInfoSource = new BehaviorSubject(JSON.parse(sessionStorage
    .getItem(this.customJobInfoKey) as string) ?? false);
  private defaultAddressIdSource = new BehaviorSubject(localStorage.getItem(this.defaultAddressIdKey) ?? '');
  private customerInfoSource = new BehaviorSubject(JSON.parse(sessionStorage
    .getItem(this.customerInfoKey) as string) ?? false);
  private urlParamsSource = new BehaviorSubject(JSON.parse(sessionStorage
    .getItem(this.urlParamsKey) as string) ?? false);
  private servicePolygonsSource = new BehaviorSubject(JSON.parse(sessionStorage
    .getItem(this.servicePolygonsKey) as string) ?? false);
  private disabledPolygonsSource = new BehaviorSubject(JSON.parse(sessionStorage
    .getItem(this.disabledPolygonsKey) as string) ?? false);
  private availableDiscountsSource = new BehaviorSubject(JSON.parse(sessionStorage
    .getItem(this.availableDiscountsKey) as string) ?? false);
  private superVoucherSource = new BehaviorSubject(JSON.parse(sessionStorage
    .getItem(this.superVoucherKey) as string) ?? false);
  private tempGeoSource = new BehaviorSubject(JSON.parse(localStorage
    .getItem(this.tempGeoKey) as string) ?? false);
  private guestAddressSource = new BehaviorSubject(JSON.parse(localStorage
    .getItem(this.guestAddressKey) as string) ?? false);
  private crntAddressSource = new BehaviorSubject(JSON.parse(localStorage
    .getItem(this.crntAddressKey) as string) ?? false);
  private zapFlowInfoSource = new BehaviorSubject(JSON.parse(sessionStorage
    .getItem(this.zapFlowInfoKey) as string) ?? false);
  private pageScrollInfoSource = new BehaviorSubject(JSON.parse(sessionStorage
    .getItem(this.pageScrollInfoKey) as string) ?? false);
  private ourServicesDataSource = new BehaviorSubject(JSON.parse(sessionStorage
    .getItem(this.ourServicesDataKey) as string) ?? false);
  private selectedStaffDataSource = new BehaviorSubject(JSON.parse(sessionStorage
    .getItem(this.selectedStaffKey) as string) ?? false);
  private categoryBookingsSource = new BehaviorSubject(JSON.parse(sessionStorage
    .getItem(this.categoryBookingsKey) as string) ?? false);
  private copiedPromoSource = new BehaviorSubject(JSON.parse(sessionStorage
    .getItem(this.copiedPromoKey) as string) ?? false);
  private parentPageUrlSource = new BehaviorSubject(JSON.parse(sessionStorage
    .getItem(this.parentPageKey) as string) ?? false);
  private serviceConfigSource = new BehaviorSubject(JSON.parse(sessionStorage
    .getItem(this.serviceConfigKey) as string) ?? "");

  crntShowOtpLoader = this.showOtpLoaderSource.asObservable();
  crntIsOtpSent = this.isOtpSentSource.asObservable();
  crntIsOtpVerified = this.isOtpVerifiedSource.asObservable();
  crntIsLoggedIn = this.isLoggedInSource.asObservable();
  crntMaidServiceInfo = this.maidServiceInfoSource.asObservable();
  crntCustomJobInfo = this.customJobInfoSource.asObservable();
  crntDefaultAddressId = this.defaultAddressIdSource.asObservable();
  crntOurServicesData = this.ourServicesDataSource.asObservable();
  crntProfileName = this.profileNameSource.asObservable();
  crntProfileImage = this.profileImageSource.asObservable();
  crntTempAddress = this.tempAddressSource.asObservable();
  crntResetTimeSlots = this.resetTimeSlotsSource.asObservable();
  crntCustomerInfo = this.customerInfoSource.asObservable();
  crntUrlParams = this.urlParamsSource.asObservable();
  crntServicePolygons = this.servicePolygonsSource.asObservable();
  crntDisabledPolygons = this.disabledPolygonsSource.asObservable();
  crntDisableSpinner = this.disableSpinnerSource.asObservable();
  crntAvailableDiscounts = this.availableDiscountsSource.asObservable();
  crntSuperVoucher = this.superVoucherSource.asObservable();
  crntVoucherConfig = this.voucherConfigSource.asObservable();
  crntUser = this.crntUserSource.asObservable();
  crntJobDetail = this.crntJobDetailSource.asObservable();
  crntSnackbarInfo = this.crntSnackbarInfoSource.asObservable();
  crntOpenPromo = this.crntOpenPromoSource.asObservable();
  crntPageReload = this.crntPageReloadSource.asObservable();
  crntTempGeo = this.tempGeoSource.asObservable();
  crntGuestAddress = this.guestAddressSource.asObservable();
  crntCrntAddress = this.crntAddressSource.asObservable();
  crntZapFlowInfo = this.zapFlowInfoSource.asObservable();
  crntPageScrollInfo = this.pageScrollInfoSource.asObservable();
  crntSelectedStaff = this.selectedStaffDataSource.asObservable();
  crntCategoryBookings = this.categoryBookingsSource.asObservable();
  crntCopiedPromo = this.copiedPromoSource.asObservable();
  crntServiceConfig = this.serviceConfigSource.asObservable();
  crntIsMonthlyPackage = this.isMonthlyPackage.asObservable();
  crntStaffRating = this.crntStaffRatingSource.asObservable();

  constructor() { }

  changeShowOtpLoader(showLoader: boolean) {
    this.showOtpLoaderSource.next(showLoader);
  }

  changeIsOtpSent(isOtpSent: boolean) {
    this.isOtpSentSource.next(isOtpSent);
  }

  changeIsOtpVerified(isOtpVerified: boolean) {
    this.isOtpVerifiedSource.next(isOtpVerified);
  }

  changeIsLoggedIn(isLoggedIn: boolean) {
    this.isLoggedInSource.next(isLoggedIn);
  }

  changeMaidServiceInfo(info: any) {
    sessionStorage.setItem(this.maidServiceInfoKey, JSON.stringify(info));
    this.maidServiceInfoSource.next(info);

  }

  changeCustomJobInfo(info: any) {
    sessionStorage.setItem(this.customJobInfoKey, JSON.stringify(info));
    this.customJobInfoSource.next(info);
  }

  changeDefaultAddressId(id: string) {
    localStorage.setItem(this.defaultAddressIdKey, id);
    this.defaultAddressIdSource.next(id);
  }

  changeCustomerInfo(info: any) {
    sessionStorage.setItem(this.customerInfoKey, JSON.stringify(info));
    this.customerInfoSource.next(info);
  }

  changeServiceConfig(serviceConfig: IServiceConfig | any) {
    sessionStorage.setItem(this.serviceConfigKey, JSON.stringify(serviceConfig));
    this.serviceConfigSource.next(serviceConfig);
  }

  clearSession() {
    sessionStorage.clear();
  }

  clearLocal() {
    localStorage.clear();
  }

  changeOurServicesData(data: any) {
    sessionStorage.setItem(this.ourServicesDataKey, JSON.stringify(data));
    this.ourServicesDataSource.next(data);
  }

  changeProfileName(name: string) {
    this.profileNameSource.next(name);
  }

  changeProfileImage(url: string) {
    this.profileImageSource.next(url);
  }

  changeTempAddress(address: Address) {
    this.tempAddressSource.next(address);
  }

  changeResetTimeSlots(reset: boolean) {
    this.resetTimeSlotsSource.next(reset);
  }

  changeUrlParams(info: any) {
    sessionStorage.setItem(this.urlParamsKey, JSON.stringify(info));
    this.urlParamsSource.next(info);
  }

  changeServicePolygons(data: any) {
    sessionStorage.setItem(this.servicePolygonsKey, JSON.stringify(data));
    this.servicePolygonsSource.next(data);
  }

  chnageDisabledPolygons(data: any) {
    sessionStorage.setItem(this.disabledPolygonsKey, JSON.stringify(data ?? false));
    this.disabledPolygonsSource.next(data);
  }

  changeDisableSpinner(disableSpinner: boolean) {
    this.disableSpinnerSource.next(disableSpinner);
  }

  changeAvailableDiscounts(data: any) {
    sessionStorage.setItem(this.availableDiscountsKey, JSON.stringify(data));
    this.availableDiscountsSource.next(data);
  }

  changeSuperVoucher(data: any) {
    sessionStorage.setItem(this.superVoucherKey, JSON.stringify(data));
    this.superVoucherSource.next(data);
  }

  changeVoucherConfig(isActive: boolean) {
    this.voucherConfigSource.next(isActive);
  }

  chnageCrntUser(data: User) {
    this.crntUserSource.next(data);
  }

  changeCrntJobDetail(data: any) {
    this.crntJobDetailSource.next(data);
  }

  changeCrntSnackbarInfo(data: any) {
    this.crntSnackbarInfoSource.next(data);
  }

  changeCrntOpenPromo(openPromo: boolean) {
    this.crntOpenPromoSource.next(openPromo);
  }

  changeCrntPageReload(isReloaded: boolean) {
    this.crntPageReloadSource.next(isReloaded);
  }

  changeTempGeo(geo: any) {
    localStorage.setItem(this.tempGeoKey, geo);
    this.tempGeoSource.next(geo);
  }

  changeGuestAddress(address: any) {
    localStorage.setItem(this.guestAddressKey, JSON.stringify(address));
    this.guestAddressSource.next(address ?? false);
  }

  changeCrntAddress(address: Address) {
    localStorage.setItem(this.crntAddressKey, JSON.stringify(address));
    this.crntAddressSource.next(address);
  }

  changeZapFlowInfo(info: any) {
    sessionStorage.setItem(this.zapFlowInfoKey, JSON.stringify(info));
    this.zapFlowInfoSource.next(info);
  }

  changePageScrollInfo(info: any) {
    sessionStorage.setItem(this.pageScrollInfoKey, JSON.stringify(info));
    this.pageScrollInfoSource.next(info);
  }

  changeSelectedStaffData(data: StaffMember[] | boolean) {
    sessionStorage.setItem(this.selectedStaffKey, JSON.stringify(data));
    this.selectedStaffDataSource.next(data);
  }

  changeCategoryBookings(data: Requirement[] | boolean) {
    sessionStorage.setItem(this.categoryBookingsKey, JSON.stringify(data));
    this.categoryBookingsSource.next(data);
  }

  changeCopiedPromo(promo: string) {
    sessionStorage.setItem(this.copiedPromoKey, JSON.stringify(promo));
    this.copiedPromoSource.next(promo);
  }

  changeParentPageUrl(url: string | boolean) {
    sessionStorage.setItem(this.parentPageKey, JSON.stringify(url));
    this.parentPageUrlSource.next(url);
  }

  changeIsMonthlyPackage(isMonthlyPackage: boolean) {
    this.isMonthlyPackage.next(isMonthlyPackage);
  }


  changeStaffRatingId(id: string) {
    this.crntStaffRatingSource.next(id);
  }

  getStaffRatingId() {
    return this.crntStaffRatingSource.value;
  }

  getIsLoggedIn() {
    return this.isLoggedInSource.value;
  }

  getSuperVoucherData() {
    return this.superVoucherSource.value;
  }

  getMaidServiceInfo() {
    return this.maidServiceInfoSource.value;
  }

  getCrntUser() {
    return this.crntUserSource.value;
  }

  getTempGeo() {
    return this.tempGeoSource.value;
  }

  getDefaultAddressId() {
    return this.defaultAddressIdSource.value;
  }

  getGuestAddress() {
    return this.guestAddressSource.value;
  }

  getCrntAddress() {
    return this.crntAddressSource.value;
  }

  getCrntCustomer() {
    return this.customerInfoSource.value;
  }

  getZapFlowInfo() {
    return this.zapFlowInfoSource.value;
  }

  getPageScrollInfo() {
    return this.pageScrollInfoSource.value;
  }

  getSelectedStaffData() {
    return this.selectedStaffDataSource.value;
  }

  getCategoryBokings() {
    return this.categoryBookingsSource.value;
  }

  getDisabledPolygons() {
    return this.disabledPolygonsSource.value;
  }

  getCopiedPromo() {
    return this.copiedPromoSource.value;
  }

  getParentPageUrl() {
    return this.parentPageUrlSource.value;
  }

  getServiceConfig() {
    return this.serviceConfigSource.value;
  }

  getIsMonthlyPackage() {
    return this.isMonthlyPackage.value;
  }
}
